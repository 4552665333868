<template>
  <v-form ref="form" v-model="isValid" @change="$emit('update:valid', isValid)">
    <v-row>
      <v-col cols="12">
        <v-label>{{ $t('company.titles.decimalNumbers') }}</v-label>
        <v-text-field
          :placeholder="(company.traceability.structure.number.afterPoint === null || company.traceability.structure.number.afterPoint === '') ? $t('company.titles.defaultValue') : ''"
          id="afterPoint"
          dense
          outlined
          color="primary"
          type="number"
          clearable
          :rules="compareMax(0,5)"
          v-model="company.traceability.structure.number.afterPoint"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import { mapGetters } from "vuex";
  import { formValidationMixin } from "@/mixins/formValidationMixin";

  export default {
    mixins: [formValidationMixin],
    props: {
      company: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapGetters({
        compareMax: 'general/compareMax'
      }),
    },
    watch: {
      'company.traceability.structure.number.afterPoint'() {
        this.touchForm();
      }
    }
  };
</script>

<style scoped>
  .display-field {
    padding: 8px;
    background-color: #f5f5f5;
    border-radius: .2rem;
    display: flex;
    align-items: center;
  }
</style>

<template>
  <v-form ref="form" v-model="isValid" @change="$emit('update:valid', isValid)">
    <v-row>
      <v-col cols="12">
        <h4 class="subtitle-1 font-weight-medium mb-4">{{ $t('common.buttons.import') }}</h4>
        <v-row>
          <v-col cols="12">
            <v-label>{{ $t('records.label') }}</v-label>
            <v-text-field
              :placeholder="(company.import.recordImportLimit === null || company.import.recordImportLimit === '') ? $t('company.titles.defaultExportRecordsValue') : ''"
              id="importRecordLimit"
              dense
              outlined
              color="primary"
              clearable
              v-model="company.import.recordImportLimit"
              @keypress="onlyAllowNumbers($event)"
              @input="filterInput('import', 'recordImportLimit')"
            />
          </v-col>
        </v-row>
      </v-col>
      
      <v-col cols="12" class="mt-4">
        <h4 class="subtitle-1 font-weight-medium mb-4">{{ $t('common.buttons.export') }}</h4>
        <v-row>
          <v-col cols="12" md="6">
            <v-label>{{ $t('records.label') }}</v-label>
            <v-text-field
              :placeholder="(company.export.recordExportLimit === null || company.export.recordExportLimit === '') ? $t('company.titles.defaultExportRecordsValue') : ''"
              id="exportRecordLimit"
              dense
              outlined
              color="primary"
              clearable
              v-model="company.export.recordExportLimit"
              @keypress="onlyAllowNumbers($event)"
              @input="filterInput('export', 'recordExportLimit')"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-label>{{ $t('survey.label') }}</v-label>
            <v-text-field
              :placeholder="(company.export.inspectionExportLimit === null || company.export.inspectionExportLimit === '') ? $t('company.titles.defaultExportInspectionsValue') : ''"
              id="inspectionExportLimit"
              dense
              outlined
              color="primary"
              clearable
              v-model="company.export.inspectionExportLimit"
              @keypress="onlyAllowNumbers($event)"
              @input="filterInput('export', 'inspectionExportLimit')"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import { formValidationMixin } from "@/mixins/formValidationMixin";
  
  export default {
    mixins: [formValidationMixin],
    props: {
      company: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        isValid: true
      };
    },
    watch: {
      'company.import.recordImportLimit'() {
        this.touchForm();
      },
      'company.export.recordExportLimit'() {
        this.touchForm();
      },
      'company.export.inspectionExportLimit'() {
        this.touchForm();
      }
    },
    methods: {
      onlyAllowNumbers(event) {
        const keyCode = event.keyCode ? event.keyCode : event.which;
        if (keyCode < 48 || keyCode > 57) {
          event.preventDefault();
        }
        this.touchForm();
      },
      filterInput(section, key) {
        const value = this.company[section][key];
        if (value !== null && value !== undefined && value !== '') {
          const numericValue = value.replace(/\D/g, '');
          this.$set(this.company[section], key, numericValue);
        }
        this.touchForm();
      }
    }
  };
</script>

<style scoped>
  .display-field {
    padding: 8px;
    background-color: #f5f5f5;
    border-radius: .2rem;
    display: flex;
    align-items: center;
  }
</style>

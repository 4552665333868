<template>
  <div class="config-root">
    <!-- Sidebar navegación -->
    <aside class="config-sidebar">
      <div class="sidebar-header">
        <button class="back-button" @click="goBack">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M19 12H5M12 19l-7-7 7-7"></path>
          </svg>
          <span>{{ $t('common.buttons.back') }}</span>
        </button>
        <span class="d-flex justify-space-between align-items-center">
          <h1 class="sidebar-title">{{ $t('company.titles.configurations') }}</h1>
          <v-tooltip v-if="hasValidationErrors" top>
            <template v-slot:activator="{ on }">
              <v-icon small size="18" color="error" v-on="on">mdi-alert-circle-outline</v-icon>
            </template>
            <span>{{ $t("common.rules.formHasErrors") }}</span>
          </v-tooltip>
        </span>
      </div>

      <nav class="sidebar-nav">
        <ul class="nav-list">
          <li v-for="(section, key) in sectionItems" :key="key"
            v-if="section.visible"
            :class="['nav-item', { 
              active: activeSection === key,
              'has-errors': section.requiresValidation && !sectionValidation[key]
            }]"
            @click="activeSection = key">
            <v-icon size="18" class="nav-icon" :color="!sectionValidation[key] ? 'error' : undefined">{{ section.icon }}</v-icon>
            <span>{{ section.title }}</span>
          </li>
        </ul>
      </nav>

      <div class="sidebar-footer" v-if="sectionItems[activeSection].showSaveButton">
        <v-btn
          color="primary"
          small
          elevation="0"
          class="t-bw-primary--text mb-2"
          @click="save"
          :disabled="hasValidationErrors || loading"
          :loading="loading"
          block
        >
          {{ $t("common.buttons.save") }}
        </v-btn>
        <v-btn
          color="neuter"
          text
          small
          elevation="0"
          @click="cancel"
          :disabled="loading"
          block
        >
          {{ $t("common.buttons.cancel") }}
        </v-btn>
      </div>
    </aside>

    <!-- Contenido principal -->
    <main class="config-content">
      <div class="content-wrapper">
        <!-- Using v-show instead of v-if to maintain component references -->
        <section v-for="(section, key) in sectionItems" :key="key" 
          v-show="activeSection === key && section.visible" 
          :class="['content-section', { 'has-errors': !sectionValidation[key] }]">
          <header class="section-header">
            <div>
              <h2 class="section-title">
                {{ section.title }}
                <v-icon v-if="!sectionValidation[key]" small color="error" class="ml-2">mdi-alert-circle-outline</v-icon>
              </h2>
              <p class="section-description">
                {{ section.description }}
              </p>
            </div>
            
            <!-- Custom actions for identity tab -->
            <button v-if="key === 'identity'" class="preview-button" @click="isPreviewMode ? restoreOriginalColors() : applyPreviewColors()">
              <svg v-if="isPreviewMode" class="preview-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M3 12a9 9 0 0 1 9-9 9.75 9.75 0 0 1 6.74 2.74L21 8"></path>
                <path d="M21 3v5h-5"></path>
                <path d="M21 12a9 9 0 0 1-9 9 9.75 9.75 0 0 1-6.74-2.74L3 16"></path>
                <path d="M8 16H3v5"></path>
              </svg>
              <svg v-else class="preview-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
              <span>{{ isPreviewMode ? $t('customization.buttons.restore') : $t('customization.buttons.preview') }}</span>
            </button>
          </header>
          
          <div class="section-card">
            <component 
              :is="section.component"
              :ref="`${key}ConfigForm`"
              :company="company" 
              :validate-now="validateSections[key]"
              @update:valid="updateSectionValidation(key, $event)" />
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import CompanyService from "@/services/CompanyService"
import GeneralConfigTab from "@/module/configuration/company/components/GeneralConfigTab"
import BlockchainConfigTab from "@/module/configuration/company/components/BlockchainConfigTab"
import ImportExportConfigTab from "@/module/configuration/company/components/ImportExportConfigTab"
import IdentityConfigTab from "@/module/configuration/company/components/IdentityConfigTab"
import LocationConfigTab from "@/module/configuration/company/components/LocationConfigTab"
import i18n from "@/plugins/i18n"
import NumberFormatService from "@/services/NumberFormatService"

const PROFILE = JSON.parse(localStorage.getItem('profile'));

export default {
  components: {
    IdentityConfigTab,
    ImportExportConfigTab,
    BlockchainConfigTab,
    GeneralConfigTab,
    LocationConfigTab,
  },
  data() {
    return {
      activeSection: 'general',
      sectionValidation: {
        general: true,
        blockchain: true,
        export: true,
        identity: true,
        locations: true
      },
      validateSections: {
        general: false,
        blockchain: false,
        export: false,
        identity: false,
        locations: false
      },
      isPreviewMode: localStorage.getItem('preview:colors') === 'true',
      company: {
        traceability: {
          structure: {
            number: {
              afterPoint: null,
            },
          },
        },
        export: {
          recordExportLimit: null,
          inspectionExportLimit: null,
        },
        import: {
          recordImportLimit: null,
        },
        locations: {
          minHeight: null,
          maxHeight: null,
        }
      },
      loading: false,
      configUser: {},
      visibleSections: {
        general: true,
        blockchain: PROFILE.company_id === 1,
        export: true,
        identity: true,
        locations: true
      },
    };
  },
  computed: {
    isFormValid() {
      // Check validation of current section
      return this.sectionValidation[this.activeSection];
    },
    
    // Additional helper computed properties
    hasValidationErrors() {
      return Object.entries(this.sectionValidation)
        .filter(([key]) => {
          const section = this.sectionItems[key];
          return this.visibleSections[key] && section.requiresValidation;
        })
        .some(([_, isValid]) => !isValid);
    },
    
    invalidSections() {
      return Object.entries(this.sectionValidation)
        .filter(([key, isValid]) => {
          const section = this.sectionItems[key];
          return this.visibleSections[key] && section.requiresValidation && !isValid;
        })
        .map(([key]) => key);
    },
    // Define tab config centrally for reuse
    sectionItems() {
      return {
        general: {
          title: this.$t('common.titles.general'),
          icon: 'mdi-information-outline',
          component: 'GeneralConfigTab',
          description: this.$t('company.titles.generalConfigDescription') || 'Configure general company preferences',
          visible: this.visibleSections.general,
          requiresValidation: true,
          showSaveButton: true
        },
        locations: {
          title: this.$t('storage.label'),
          icon: 'mdi-map-marker-outline',
          component: 'LocationConfigTab',
          description: this.$t('company.titles.locationsConfigDescription') || 'Configure location settings such as altitudelimits',
          visible: this.visibleSections.locations,
          requiresValidation: true,
          showSaveButton: true
        },
        export: {
          title: `${this.$t('common.buttons.import').charAt(0).toUpperCase() + this.$t('common.buttons.import').slice(1).toLowerCase()}/${this.$t('common.buttons.export').charAt(0).toUpperCase() + this.$t('common.buttons.export').slice(1).toLowerCase()}`,
          icon: 'mdi-swap-vertical',
          component: 'ImportExportConfigTab',
          description: this.$t('company.titles.importExportConfigDescription') || 'Configure import and export limits',
          visible: this.visibleSections.export,
          requiresValidation: true,
          showSaveButton: true
        },
        blockchain: {
          title: 'Blockchain',
          icon: 'mdi-cube-outline',
          component: 'BlockchainConfigTab',
          description: this.$t('company.titles.blockchainConfigDescription') || 'Manage blockchain wallets and settings',
          visible: this.visibleSections.blockchain,
          requiresValidation: false,
          showSaveButton: false
        },
        identity: {
          title: this.$t('customization.titles.identity'),
          icon: 'mdi-palette-outline',
          component: 'IdentityConfigTab',
          description: this.$t('company.titles.identityConfigDescription') || 'Customize theme colors',
          visible: this.visibleSections.identity,
          requiresValidation: true,
          showSaveButton: true
        }
      };
    },
    
    // Check if any section has validation errors
    hasValidationErrors() {
      return Object.entries(this.sectionValidation)
        .filter(([key]) => {
          const section = this.sectionItems[key];
          return this.visibleSections[key] && section.requiresValidation;
        })
        .some(([_, isValid]) => !isValid);
    },
    
    // Get sections with errors for navigation
    invalidSections() {
      return Object.entries(this.sectionValidation)
        .filter(([key, isValid]) => {
          const section = this.sectionItems[key];
          return this.visibleSections[key] && section.requiresValidation && !isValid;
        })
        .map(([key]) => key);
    }
  },
  watch: {
    activeSection(newSection) {
      console.log(`Tab changed to: ${newSection}, validation state: ${this.sectionValidation[newSection]}`);
      
      // Force validation of the current section when switching tabs
      this.$nextTick(() => {
        this.validateCurrentSection();
      });
    }
  },
  async created() {
    this.configUser = JSON.parse(localStorage.getItem('config'))
    console.log('>>>>> config user', this.configUser)
    this.company.traceability.structure.number = this.configUser.company.traceability.structure.number
    this.company.import.recordImportLimit = this.configUser.company.import.recordImportLimit
    this.company.export.recordExportLimit = this.configUser.company.export.recordExportLimit
    this.company.export.inspectionExportLimit = this.configUser.company.export.inspectionExportLimit
    this.company.locations.minHeight = this.configUser.company.locations.minHeight
    this.company.locations.maxHeight = this.configUser.company.locations.maxHeight
  },
  methods: {
    ...mapActions({
      loadConfigUser: 'authority/loadConfigUser',
    }),

    async save() {
      // Validate all sections before saving
      await this.validateAllSections();
      
      if (this.hasValidationErrors) {
        // Show error notification with guidance
        this.$toast.error(this.$t('common.validation.pleaseFillRequiredFields'), {
          color: 'error',
          queueable: true
        });
        
        // Switch to the first tab with errors
        if (this.invalidSections.length > 0) {
          this.activeSection = this.invalidSections[0];
        }
        
        return;
      }
      
      // Continue with save process...
      this.loading = true;

      try {
        // Create a safe function to call methods on components only if they exist
        const safeCall = async (ref, method, fallback) => {
          if (this.$refs[ref] && typeof this.$refs[ref][method] === 'function') {
            return await this.$refs[ref][method]();
          }
          return fallback;
        };

        // Save identity colors if that section is active
        if (this.visibleSections.identity) {
          await safeCall('identityConfigForm', 'saveColors', null);
          localStorage.removeItem('preview:colors');
          this.isPreviewMode = false;
        }

        // Save other configuration
        const config = this.manageModule('create');

        // Update configUser with new values
        this.updateConfigUserValues(config);

        await CompanyService.setConfig(config);

        localStorage.setItem('config', JSON.stringify(this.configUser));

        this.$toast.success(i18n.t('common.notification.configSave'), {
          color: 'success',
          queueable: true
        });
      } catch (error) {
        console.error('Error saving configuration:', error);
        this.$toast.error(i18n.t('common.notification.configSaveError'), {
          color: 'error',
          queueable: true
        });
      } finally {
        this.loading = false;
      }
    },

    updateConfigUserValues(config) {
      const updates = {
        // Records
        'company.import.recordImportLimit': config.recordImportLimit,
        'company.export.recordExportLimit': config.recordExportLimit,
        // Inspections
        'company.export.inspectionExportLimit': config.inspectionExportLimit,
        // Locations
        'company.locations.minHeight': config.locationMinHeight,
        'company.locations.maxHeight': config.locationMaxHeight
      };

      Object.entries(updates).forEach(([path, value]) => {
        this.setNestedValue(this.configUser, path, value);
      });
    },

    setNestedValue(obj, path, value) {
      const keys = path.split('.');
      let current = obj;
      
      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]];
      }
      
      current[keys[keys.length - 1]] = value;
    },
    manageModule(method) {
      let config = {};

      if (method === 'create') {
        // Normalize height values for storage
        const locationMinHeight = NumberFormatService.normalizeForServer(this.company.locations.minHeight);
        const locationMaxHeight = NumberFormatService.normalizeForServer(this.company.locations.maxHeight);
        
        config = {
          companyStructuresPreferences: {
            afterPoint: this.company.traceability.structure.number.afterPoint
          },
          recordImportLimit: this.company.import.recordImportLimit,
          recordExportLimit: this.company.export.recordExportLimit,
          inspectionExportLimit: this.company.export.inspectionExportLimit,
          locationMinHeight: locationMinHeight,
          locationMaxHeight: locationMaxHeight
        };
        
        this.configUser.company.traceability.structure.number = this.company.traceability.structure.number;
        this.configUser.company.locations.minHeight = locationMinHeight;
        this.configUser.company.locations.maxHeight = locationMaxHeight;
        
        return config;
      } else if (method === 'reset') {
        config = {
          companyStructuresPreferences: {
            afterPoint: null
          },
          recordImportLimit: 0,
          recordExportLimit: 0,
          inspectionExportLimit: 0,
          locationMinHeight: null,
          locationMaxHeight: null
        };
        this.company.traceability.structure.number.afterPoint = null;
        this.company.locations.minHeight = null;
        this.company.locations.maxHeight = null;
        this.configUser.company.traceability.structure.number = this.company.traceability.structure.number;
        this.configUser.company.height = this.company.height;
        return config;
      }
    },

    async applyPreviewColors() {
      if (this.$refs.identityConfigForm) {
        await this.$refs.identityConfigForm.applyPreview();
        this.isPreviewMode = true;
        localStorage.setItem('preview:colors', 'true');
      }
    },

    async restoreOriginalColors() {
      if (this.$refs.identityConfigForm) {
        await this.$refs.identityConfigForm.restoreOriginalColors();
        this.isPreviewMode = false;
        localStorage.removeItem('preview:colors');
        await this.$refs.identityConfigForm.loadInitConfiguration();
      }
    },

    goBack() {
      this.$router.push('/companies');
    },

    async cancel() {
      this.loading = true;
      try {
        // Reset identity colors if needed
        if (this.visibleSections.identity && this.isPreviewMode && this.$refs.identityConfigForm) {
          await this.restoreOriginalColors();
        }

        // Reset other configuration data
        await this.loadConfigUser([this.$toast]);

        // Reset to initial values from configUser
        this.company.traceability.structure.number = this.configUser.company.traceability.structure.number;
        this.company.import.recordImportLimit = this.configUser.company.import.recordImportLimit;
        this.company.export.recordExportLimit = this.configUser.company.export.recordExportLimit;
        this.company.export.inspectionExportLimit = this.configUser.company.export.inspectionExportLimit;
        this.company.locations.minHeight = this.configUser.company.locations?.minHeight || null;
        this.company.locations.maxHeight = this.configUser.company.locations?.maxHeight || null;

        // Navigate back to company page using direct path
        this.$router.push('/companies');
      } finally {
        this.loading = false;
      }
    },
    updateSectionValidation(section, isValid) {
      console.log(`Validation update: ${section} is ${isValid ? 'valid' : 'invalid'}`);
      // Si la sección no requiere validación, siempre establecerla como válida
      if (!this.sectionItems[section].requiresValidation) {
        this.sectionValidation[section] = true;
      } else {
        this.sectionValidation[section] = isValid;
      }
      
      // Reset validateSections flag after validation
      this.$nextTick(() => {
        this.validateSections[section] = false;
      });
    },
    
    validateCurrentSection() {
      const currentSection = this.activeSection;
      console.log(`Validating current section: ${currentSection}`);
      
      // Si la sección no requiere validación, retornar true inmediatamente
      if (!this.sectionItems[currentSection].requiresValidation) {
        this.sectionValidation[currentSection] = true;
        return true;
      }
      
      // Set flag to trigger validation in child component
      this.validateSections[currentSection] = true;
      
      // Check if we have a reference to the current tab component
      const refName = `${currentSection}ConfigForm`;
      if (this.$refs[refName] && typeof this.$refs[refName].validateForm === 'function') {
        const isValid = this.$refs[refName].validateForm();
        this.sectionValidation[currentSection] = isValid;
        return isValid;
      }
      
      return this.sectionValidation[currentSection];
    },
    
    validateAllSections() {
      // Set validate flag for all sections
      Object.keys(this.validateSections).forEach(section => {
        this.validateSections[section] = true;
      });
      
      // Wait for validations to complete
      return this.$nextTick().then(() => {
        return !this.hasValidationErrors;
      });
    },
  }
};
</script>


<style lang="scss" scoped>
/* Estilo base inspirado en GitHub/Stripe */
.config-root {
  display: flex;
  width: 100%;
  min-height: calc(100vh - 64px);
  background-color: #fafbfc;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
}

/* Sidebar */
.config-sidebar {
  width: 250px;
  background-color: white;
  border-right: 1px solid #e1e4e8;
  display: flex;
  flex-direction: column;
  position: fixed;
  height: calc(100vh - 64px);
  z-index: 5;
}

.sidebar-header {
  padding: 20px 16px;
  border-bottom: 1px solid #e1e4e8;
}

.back-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  padding: 6px 0;
  font-size: 14px;
  color: #586069;
  cursor: pointer;
  transition: color 0.2s;
}

.back-button:hover {
  color: #0366d6;
}

.back-button svg {
  margin-right: 4px;
}

.sidebar-title {
  font-size: 16px;
  font-weight: 600;
  color: #24292e;
}

.sidebar-nav {
  flex: 1;
  padding: 8px 0;
  overflow-y: auto;
}

.nav-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  color: #586069;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  border-left: 3px solid transparent;
}

.nav-item.active {
  background-color: #f6f8fa;
  color: #24292e;
  font-weight: 500;
  border-left-color: var(--v-primary-base);
}

.nav-item:hover:not(.active) {
  background-color: #f6f8fa;
}

.nav-icon {
  margin-right: 10px;
}

.sidebar-footer {
  padding: 16px;
  border-top: 1px solid #e1e4e8;
}

.save-button,
.cancel-button {
  width: 100%;
  font-size: 14px;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s, opacity 0.2s;
}

.save-button {
  background-color: #2ea44f;
  color: white;
  margin-bottom: 8px;
}

.save-button:hover:not(:disabled) {
  background-color: #2c974b;
}

.save-button:disabled,
.cancel-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.cancel-button {
  background-color: transparent;
  color: #586069;
  border: 1px solid #e1e4e8;
}

.cancel-button:hover:not(:disabled) {
  background-color: #f6f8fa;
}

/* Loader para el botón de guardar */
.loader {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Contenido principal */
.config-content {
  flex: 1;
  margin-left: 250px;
  padding: 32px;
}

.content-wrapper {
  max-width: 900px;
  margin: 0 auto;
}

.content-section {
  width: 100%;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
}

.section-title {
  font-size: 24px;
  font-weight: 600;
  color: #24292e;
  margin: 0;
  margin-bottom: 8px;
}

.section-description {
  font-size: 14px;
  color: #586069;
  margin: 0;
  max-width: 650px;
  line-height: 1.5;
}

.section-card {
  background-color: white;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
  padding: 24px;
}

.preview-button {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #e1e4e8;
  color: #586069;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.preview-button:hover {
  background-color: #f6f8fa;
}

.preview-icon {
  margin-right: 6px;
}

/* Transiciones entre secciones */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Responsive */
@media (max-width: 768px) {
  .config-sidebar {
    width: 100%;
    position: relative;
    height: auto;
  }

  .config-content {
    margin-left: 0;
  }

  .config-root {
    flex-direction: column;
  }

  .section-header {
    flex-direction: column;
  }

  .preview-button {
    margin-top: 16px;
    align-self: flex-start;
  }
}

/* New styles for error indicators */
.nav-item.has-errors {
  border-left-color: var(--v-error-base);
  
  &:not(.active) {
    color: var(--v-error-base);
    background-color: rgba(255, 0, 0, 0.05);
  }
  
  &.active {
    background-color: rgba(255, 0, 0, 0.08);
  }
}

.validation-error-banner {
  background-color: rgba(255, 0, 0, 0.1);
  color: var(--v-error-darken1-base);
  padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.section-title .v-icon {
  vertical-align: middle;
}
</style>

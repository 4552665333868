<template>
  <v-form ref="form" v-model="isValid" @change="$emit('update:valid', isValid)">
    <v-row>
      <v-col cols="12">
        <FormWalletComponent @isValid="handleWalletValidation" />
      </v-col>
      <v-col cols="12">
        <WalletsTable />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import WalletsTable from "@/components/UserOptions/Blockcahin/WalletsTable";
  import FormWalletComponent from "@/components/UserOptions/Blockcahin/WalletFormComponent";

  export default {
    components: { FormWalletComponent, WalletsTable },
    data() {
      return {
        isValid: true
      };
    },
    methods: {
      handleWalletValidation(isValid) {
        this.isValid = isValid;
        this.$emit('update:valid', isValid);
      }
    }
  };
</script>

<style scoped>
  .display-field {
    padding: 8px;
    background-color: #f5f5f5;
    border-radius: .2rem;
    display: flex;
    align-items: center;
  }
</style>


  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { mapActions, mapGetters } from 'vuex';
  import { ThemeColors } from '@customization/models/ColorConfig';
  import i18n from "@/plugins/i18n"
  import { TranslateResult } from "vue-i18n"

  @Component({
    computed: {
      ...mapGetters('customization', ['theme', 'themeColors']),
    },
    methods: {
      ...mapActions('customization', ['UPDATE_THEME_COLORS', 'SAVE_CONFIGURATION'])
    },
  })
  export default class IdentityConfigTab extends Vue {
    // Form validation
    isFormValid: boolean = true;

    // Drawer state
    isDrawerOpen: boolean = false;

    // Initialize colors based on themeColors
    originalColors: ThemeColors = { ...this.themeColors };
    localColors: ThemeColors = { ...this.themeColors };
    previewColors: ThemeColors = { ...this.themeColors };

    // Menus for each color picker
    menus: { [key: string]: boolean } = {};

    // Color hovered for showing pencil icon
    hoverColor: string | null = null;

    // Descriptions for each color
    colorDescriptions: { [key: string]: TranslateResult } = {
      primary: i18n.t('customization.notification.primary'),
      secondary: i18n.t('customization.notification.secondary')
    };

    // Vuex Getter for themeColors
    get themeColors(): ThemeColors {
      return this.$store.getters['customization/themeColors'];
    }

    // Filter only the colors that can be modified
    get filteredColors(): Partial<ThemeColors> {
      const modifiableKeys = ['primary', 'secondary'];
      const filteredColors: Partial<ThemeColors> = {};

      modifiableKeys.forEach((key) => {
        if (key in this.localColors) {
          filteredColors[key] = this.localColors[key];
        }
      });

      return filteredColors;
    }

    // Toggle drawer state (not used, but kept for completeness)
    toggleDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen;

      if (this.isDrawerOpen) {
        this.localColors = { ...this.themeColors };
      } else {
        this.$store.commit('customization/UPDATE_THEME_COLORS', this.originalColors);
        this.clearColorStates();
      }
    }

    // Cancel color editing in the drawer
    cancelColorEdit(key: string) {
      this.localColors[key] = this.originalColors[key];
      this.menus[key] = false;
    }

    // Apply a preview of the selected colors in the drawer
    applyPreview() {
      this.previewColors = { ...this.themeColors };
      this.$store.commit('customization/UPDATE_THEME_COLORS', this.localColors);
    }

    // Restore original colors
    restoreOriginalColors() {
      this.localColors = { ...this.originalColors };
      this.$store.commit('customization/UPDATE_THEME_COLORS', this.originalColors);
    }

    // Save the selected colors permanently
    async saveColors() {
      this.originalColors = { ...this.localColors };
      await this.$store.dispatch('customization/UPDATE_THEME_COLORS', this.localColors);
      await this.$store.dispatch('customization/SAVE_CONFIGURATION');
      this.clearColorStates();
    }

    // Clear the state of local and preview colors
    clearColorStates() {
      this.localColors = { ...this.themeColors };
      this.previewColors = { ...this.themeColors };
    }

    async loadInitConfiguration() {
      await this.$store.dispatch('customization/LOAD_CONFIGURATION');
      this.originalColors = { ...this.themeColors };
      this.localColors = { ...this.themeColors };
      this.previewColors = { ...this.themeColors };
    }

    // Hook for when the component is mounted
    async mounted() {
      if (!localStorage.getItem('preview:colors')) {
        await this.loadInitConfiguration();
      }
      
      // Emit initial validation state
      this.$emit('update:valid', this.isFormValid);
    }
  }

import i18n from '@/plugins/i18n';

class NumberFormatService {
  constructor() {}

  formatNumber(number, afterPoint, region) {
    const formatter = new Intl.NumberFormat(region.toLowerCase(), {
      minimumFractionDigits: afterPoint,
      maximumFractionDigits: afterPoint,
    })

    if (region.toLowerCase() === "es-es") {
      return this.formatNumberForSpain(number, afterPoint)
    }

    return formatter.format(number)
  }

  formatNumberSeries(numbersArray, afterPoint, region) {
    return numbersArray.map(number =>
      this.formatNumber(number, afterPoint, region.toLowerCase())
    )
  }

  formatNumberForSpain(number, afterPoint) {
    let [integerPart, decimalPart] = number.toString().split(".")

    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".")

    if (decimalPart) {
      decimalPart = parseFloat("0." + decimalPart)
        .toFixed(afterPoint)
        .split(".")[1]
    } else {
      decimalPart = "".padEnd(afterPoint, "0")
    }

    return `${integerPart},${decimalPart}`
  }

  /**
   * Gets the decimal separator for the current locale
   * @returns {string} ',' for PT-BR and ES-ES, '.' for other locales
   */
  getDecimalSeparator() {
    const locale = i18n.locale
    // Portuguese and Spanish use comma as decimal separator
    return locale === "pt-br" || locale === "es-es" ? "," : "."
  }

  /**
   * Gets the thousands separator for the current locale
   * @returns {string} '.' for PT-BR and ES-ES, ',' for other locales
   */
  getThousandsSeparator() {
    const locale = i18n.locale
    // Portuguese and Spanish use period as thousands separator
    return locale === "pt-br" || locale === "es-es" ? "." : ","
  }

  /**
   * Parses a localized number string to a JavaScript number
   * @param {string|number} value - The value to parse
   * @returns {number|null} The parsed number or null if invalid
   */
  parseLocalizedNumber(value) {
    if (value === null || value === undefined || value === "") return null

    // Convert to string if it's already a number
    const strValue = String(value)

    // Replace comma with period for JavaScript parsing
    const normalizedValue = strValue.replace(",", ".")
    const result = parseFloat(normalizedValue)

    return isNaN(result) ? null : result
  }

  /**
   * Formats a number according to the current locale
   * @param {number|string} value - The value to format
   * @param {number} decimals - The number of decimal places (default: 2)
   * @returns {string} The formatted number as a string
   */
  formatLocalizedNumber(value, decimals = 2) {
    if (value === null || value === undefined || value === "") return ""

    const decimalSeparator = this.getDecimalSeparator()
    const thousandsSeparator = this.getThousandsSeparator()

    // Parse to number if it's a string
    const numValue =
      typeof value === "string" ? this.parseLocalizedNumber(value) : value

    if (numValue === null || isNaN(numValue)) return ""

    // Format the number with the specified decimal places
    const parts = numValue.toFixed(decimals).split(".")

    // Add thousands separators
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)

    // Join with the appropriate decimal separator
    return parts.join(decimalSeparator)
  }

  /**
   * Validates if a keypress event should be allowed for a numeric field
   * @param {Event} event - The keypress event
   * @param {boolean} allowDecimal - Whether to allow decimal point
   * @returns {boolean} True if the keypress should be allowed
   */
  validateNumberKeypress(event, allowDecimal = false) {
    const keyCode = event.keyCode ? event.keyCode : event.which
    const decimalSeparator = this.getDecimalSeparator()
    const decimalKeyCode = decimalSeparator === "," ? 44 : 46 // 44 for comma, 46 for period
    const altKeyCode = decimalSeparator === "," ? 46 : 44 // Alternative decimal separator

    // Allow numbers
    if (keyCode >= 48 && keyCode <= 57) {
      return true
    }

    // Allow decimal separator if enabled
    if (
      allowDecimal &&
      (keyCode === decimalKeyCode || keyCode === altKeyCode)
    ) {
      const value = event.target.value
      // Prevent multiple decimal separators
      if (!(value.includes(",") || value.includes("."))) {
        return true
      }
      event.preventDefault()
      return false
    }

    // Allow control keys like backspace (8), delete (46), arrow keys, etc.
    if ([8, 9, 37, 38, 39, 40].includes(keyCode)) {
      return true
    }

    // Disallow all other keypresses
    event.preventDefault()
    return false
  }

  /**
   * Filters and normalizes input for numeric fields
   * @param {Object} obj - The object containing the field
   * @param {string} key - The field key
   * @param {Vue} vueInstance - Vue instance for reactivity
   * @param {number} maxDecimals - Maximum decimal places
   * @param {number} maxDigits - Maximum integer digits (defaults to 8)
   */
  filterNumericInput(obj, key, vueInstance, maxDecimals = 2, maxDigits = 8) {
    let value = obj[key]
    if (value === null || value === undefined || value === "") return

    const decimalSeparator = this.getDecimalSeparator()
    const altDecimalSeparator = decimalSeparator === "," ? "." : ","

    // Replace alternative separator with the correct one
    if (value.includes(altDecimalSeparator)) {
      value = value.replace(altDecimalSeparator, decimalSeparator)
    }

    // Filter non-numeric characters, but keep decimal separator
    let numericValue;
    const decimalRegex = decimalSeparator === "," ? /[^0-9,]/g : /[^0-9.]/g
    numericValue = value.replace(decimalRegex, "")

    // Handle multiple decimal separators (keep only the first one)
    const parts = numericValue.split(decimalSeparator)

    // Limit integer part to maxDigits
    if (parts[0].length > maxDigits) {
      parts[0] = parts[0].substring(0, maxDigits);
    }

    if (parts.length > 1) {
      // Limit decimal places
      numericValue =
        parts[0] +
        decimalSeparator +
        parts.slice(1).join("").substring(0, maxDecimals)
    } else {
      numericValue = parts[0];
    }

    // Update the value using Vue's reactivity system
    vueInstance.$set(obj, key, numericValue)
  }

  /**
   * Normalize a localized number string for server storage (always with period as decimal separator)
   * @param {string|number} value - The value to normalize
   * @returns {string|null} The normalized number with period as decimal separator
   */
  normalizeForServer(value) {
    if (value === null || value === undefined || value === '') return null;
    
    // If it's already a number, convert to string with period
    if (typeof value === 'number') return String(value);
    
    // For string values, make sure we use period as decimal separator
    return String(value).replace(',', '.');
  }
  
  /**
   * Process an object's numeric properties to normalize them for server storage
   * @param {Object} obj - Object with properties to process
   * @param {Array} numericFields - Array of field names that should be normalized
   * @returns {Object} New object with normalized values
   */
  normalizeObjectForServer(obj, numericFields) {
    const result = { ...obj };
    
    numericFields.forEach(field => {
      if (obj[field] !== undefined && obj[field] !== null) {
        result[field] = this.normalizeForServer(obj[field]);
      }
    });
    
    return result;
  }
}

export default new NumberFormatService()

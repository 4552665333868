import i18n from "@/plugins/i18n";
import NumberFormatService from "@/services/NumberFormatService";

/**
 * Validation rules for height fields
 */
export default {
  /**
   * Get validation rules for height fields based on field type and configuration
   */
  getHeightValidationRules(type, item = {}, config = {}) {
    // Verificar si hay valores y convertirlos a números para comparación
    const minHeightValue = item.minHeight ? NumberFormatService.parseLocalizedNumber(item.minHeight) : null;
    const maxHeightValue = item.maxHeight ? NumberFormatService.parseLocalizedNumber(item.maxHeight) : null;
    const mediumHeightValue = item.mediumHeight ? NumberFormatService.parseLocalizedNumber(item.mediumHeight) : null;
    
    // Config values
    const configMinHeight = config.minHeight ? NumberFormatService.parseLocalizedNumber(config.minHeight) : null;
    const configMaxHeight = config.maxHeight ? NumberFormatService.parseLocalizedNumber(config.maxHeight) : null;

    // Basic positive number validation for all fields
    const rules = [
      v => !v || NumberFormatService.parseLocalizedNumber(v) >= 0 || i18n.t('storage.rules.positiveNumberOnly')
    ];
    
    // Validaciones específicas por tipo
    switch (type) {
      case 'min':
        // 1. El valor mínimo no puede exceder el valor máximo actual si existe
        if (maxHeightValue !== null) {
          rules.push(v => {
            if (!v) return true;
            if (!maxHeightValue) return true;
            
            const vValue = NumberFormatService.parseLocalizedNumber(v);
            // Make the comparison explicitly check if min > max
            return !(vValue > maxHeightValue) || i18n.t('storage.rules.minShouldBeLessOrEqual');
          });
        }
        
        // 2. El valor mínimo debe respetar el mínimo global configurado
        if (configMinHeight !== null) {
          rules.push(v => {
            if (!v) return true;
            const vValue = NumberFormatService.parseLocalizedNumber(v);
            return vValue >= configMinHeight || 
              `${i18n.t('storage.rules.belowConfigMin')} (${config.minHeight})`;
          });
        }
        
        // 3. El valor mínimo no debe exceder el máximo global configurado
        if (configMaxHeight !== null) {
          rules.push(v => {
            if (!v) return true;
            const vValue = NumberFormatService.parseLocalizedNumber(v);
            return vValue <= configMaxHeight || 
              `${i18n.t('storage.rules.exceedsConfigMax')} (${config.maxHeight})`;
          });
        }
        break;
        
      case 'max':
        // 1. El valor máximo no puede ser menor que el valor mínimo actual si existe
        if (minHeightValue !== null) {
          rules.push(v => {
            if (!v) return true;
            if (!minHeightValue) return true;
            
            const vValue = NumberFormatService.parseLocalizedNumber(v);
            // Make the comparison explicitly check if max < min
            return !(vValue < minHeightValue) || i18n.t('storage.rules.maxShouldBeGreaterOrEqual');
          });
        }
        
        // 2. El valor máximo debe respetar el mínimo global configurado
        if (configMinHeight !== null) {
          rules.push(v => {
            if (!v) return true;
            const vValue = NumberFormatService.parseLocalizedNumber(v);
            return vValue >= configMinHeight || 
              `${i18n.t('storage.rules.belowConfigMin')} (${config.minHeight})`;
          });
        }
        
        // 3. El valor máximo no debe exceder el máximo global configurado
        if (configMaxHeight !== null) {
          rules.push(v => {
            if (!v) return true;
            const vValue = NumberFormatService.parseLocalizedNumber(v);
            return vValue <= configMaxHeight || 
              `${i18n.t('storage.rules.exceedsConfigMax')} (${config.maxHeight})`;
          });
        }
        break;
        
      case 'medium':
        // 1. Primero verificamos contra los valores actuales de min y max
        if (minHeightValue !== null) {
          rules.push(v => {
            if (!v) return true;
            const vValue = NumberFormatService.parseLocalizedNumber(v);
            return vValue >= minHeightValue || 
              `${i18n.t('storage.rules.belowMinHeight')} (${item.minHeight})`;
          });
        }
        
        if (maxHeightValue !== null) {
          rules.push(v => {
            if (!v) return true;
            const vValue = NumberFormatService.parseLocalizedNumber(v);
            return vValue <= maxHeightValue || 
              `${i18n.t('storage.rules.aboveMaxHeight')} (${item.maxHeight})`;
          });
        }
        
        // 2. Si no hay valores actuales, verificamos contra la configuración global
        if (minHeightValue === null && configMinHeight !== null) {
          rules.push(v => {
            if (!v) return true;
            const vValue = NumberFormatService.parseLocalizedNumber(v);
            return vValue >= configMinHeight || 
              `${i18n.t('storage.rules.belowMinHeight')} (${config.minHeight})`;
          });
        }
        
        if (maxHeightValue === null && configMaxHeight !== null) {
          rules.push(v => {
            if (!v) return true;
            const vValue = NumberFormatService.parseLocalizedNumber(v);
            return vValue <= configMaxHeight || 
              `${i18n.t('storage.rules.aboveMaxHeight')} (${config.maxHeight})`;
          });
        }
        break;
    }

    return rules;
  },

  /**
   * Validate min and max height in relation to each other
   * 
   * @param {any} minHeight - The minimum height value
   * @param {any} maxHeight - The maximum height value
   * @returns {object} Validation result with error messages
   */
  validateMinMaxHeight(minHeight, maxHeight) {
    const result = {
      isValid: true,
      minHeightError: null,
      maxHeightError: null
    };
    
    if (!minHeight || !maxHeight) {
      return result;
    }
    
    const minValue = NumberFormatService.parseLocalizedNumber(minHeight);
    const maxValue = NumberFormatService.parseLocalizedNumber(maxHeight);
    
    console.log('validateMinMaxHeight', minValue, maxValue, minValue > maxValue);
    
    // Ensure the comparison is correct - explicit check
    if (minValue > maxValue) {
      result.isValid = false;
      result.minHeightError = i18n.t('storage.rules.minShouldBeLessOrEqual');
      result.maxHeightError = i18n.t('storage.rules.maxShouldBeGreaterOrEqual');
    }
    
    return result;
  },

  /**
   * Generate height hint messages
   * 
   * @param {string} type - The type of hint ('min', 'max', or 'medium')
   * @param {object} item - The current item (containing height values)
   * @param {object} config - Configuration object with height limits
   * @returns {string} The appropriate hint message
   */
  getHeightHint(type, item = {}, config = {}) {
    if (type === 'min') {
      return config.minHeight 
        ? `${i18n.t('storage.hints.minimumHeight')}: ${config.minHeight}`
        : i18n.t('storage.hints.enterMinHeight');
    }
    
    if (type === 'max') {
      return config.maxHeight 
        ? `${i18n.t('storage.hints.maximumHeight')}: ${config.maxHeight}`
        : i18n.t('storage.hints.enterMaxHeight');
    }
    
    if (type === 'medium') {
      const minValue = item.minHeight || config.minHeight;
      const maxValue = item.maxHeight || config.maxHeight;
      
      if (minValue && maxValue) {
        return `${i18n.t('storage.hints.mediumHeightRange')}: ${minValue} - ${maxValue}`;
      } else if (minValue) {
        return `${i18n.t('storage.hints.mediumHeightMin')}: ${minValue}`;
      } else if (maxValue) {
        return `${i18n.t('storage.hints.mediumHeightMax')}: ${maxValue}`;
      }
      
      return i18n.t('storage.hints.enterMediumHeight');
    }
    
    return '';
  }
};

<template>
  <v-form ref="form" v-model="isValid" @change="$emit('update:valid', isValid)">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="6">
            <v-label>{{ $t('storage.fields.minimumHeight') || 'Minimum Height (m)' }}</v-label>
            <v-text-field
              id="minHeight"
              dense
              outlined
              color="primary"
              clearable
              v-model="company.locations.minHeight"
              :rules="heightValidationRules('min')"
              @keypress="onlyAllowNumbers($event, true)"
              @input="filterInput('minHeight')"
              :hint="$t('storage.hints.minimumHeight')"
              persistent-hint
              :error-messages="minHeightError"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-label>{{ $t('storage.fields.maximumHeight') || 'Maximum Height (m)' }}</v-label>
            <v-text-field
              id="maxHeight"
              dense
              outlined
              color="primary"
              clearable
              v-model="company.locations.maxHeight"
              :rules="heightValidationRules('max')"
              @keypress="onlyAllowNumbers($event, true)"
              @input="filterInput('maxHeight')"
              :hint="$t('storage.hints.maximumHeight')"
              persistent-hint
              :error-messages="maxHeightError"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex';
  import NumberFormatService from "@/services/NumberFormatService";
  import Rules from "@/module/configuration/storage/validations";
  import { formValidationMixin } from "@/mixins/formValidationMixin";
  
  export default {
    mixins: [formValidationMixin],
    props: {
      company: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        minHeightError: null,
        maxHeightError: null,
        // Track previous values for comparison
        prevMinHeight: null,
        prevMaxHeight: null
      };
    },
    computed: {
      ...mapGetters({
        onlyPositiveNumberFormat: "general/onlyPositiveNumberFormat",
      }),
      
      // Determine decimal separator based on current locale
      decimalSeparator() {
        const locale = this.$i18n.locale;
        // For Portuguese and Spanish, use comma as decimal separator
        return locale === 'pt-br' || locale === 'es-es' ? ',' : '.';
      },
      
      // Alternative decimal separator for input validation
      altDecimalSeparator() {
        return this.decimalSeparator === ',' ? '.' : ',';
      }
    },
    created() {
      const CONFIG = JSON.parse(localStorage.getItem("config"));

      console.log('LocationConfigTab created - Initial values:', {
        companyMinHeight: this.company.locations.minHeight, 
        companyMaxHeight: this.company.locations.maxHeight,
        configMinHeight: CONFIG?.company?.locations?.minHeight,
        configMaxHeight: CONFIG?.company?.locations?.maxHeight
      });
      
      // Only set values if they are actually null/undefined, not if they are explicitly set to 0
      if (this.company.locations.minHeight === null || this.company.locations.minHeight === undefined) {
        this.company.locations.minHeight = CONFIG?.company?.locations?.minHeight !== undefined ? 
          CONFIG.company.locations.minHeight : null;
      }
      
      if (this.company.locations.maxHeight === null || this.company.locations.maxHeight === undefined) {
        this.company.locations.maxHeight = CONFIG?.company?.locations?.maxHeight !== undefined ? 
          CONFIG.company.locations.maxHeight : null;
      }

      console.log('LocationConfigTab created - Final values:', {
        minHeight: this.company.locations.minHeight, 
        maxHeight: this.company.locations.maxHeight
      });
    },
    watch: {
      'company.locations.minHeight': {
        handler: function(newVal, oldVal) {
          this.prevMinHeight = oldVal;
          this.$nextTick(() => {
            this.clearValidationErrors();
            this.validateHeights();
            this.touchForm(); // Add this to trigger validation
          });
        },
        immediate: true
      },
      'company.locations.maxHeight': {
        handler: function(newVal, oldVal) {
          this.prevMaxHeight = oldVal;
          this.$nextTick(() => {
            this.clearValidationErrors();
            this.validateHeights();
            this.touchForm(); // Add this to trigger validation
          });
        },
        immediate: true
      }
    },
    methods: {
      onlyAllowNumbers(event, allowDecimal = false) {
        // Use the centralized service method
        return NumberFormatService.validateNumberKeypress(event, allowDecimal);
      },
      
      filterInput(key) {
        // Use the centralized service method
        NumberFormatService.filterNumericInput(this.company.locations, key, this, 2);
        
        // Force clear validation errors
        this.clearValidationErrors();
        
        // Run validation after updating the value
        this.$nextTick(() => {
          this.validateHeights();
        });
      },
      
      heightValidationRules(type) {
        return Rules.getHeightValidationRules(type, this.company.locations, {});
      },
      
      clearValidationErrors() {
        // Clear all error states
        this.minHeightError = null;
        this.maxHeightError = null;
        // Force the validation to run from a clean state
        this.$refs.form && this.$refs.form.resetValidation();
      },
      
      validateHeights() {
        const minHeight = this.company.locations.minHeight;
        const maxHeight = this.company.locations.maxHeight;
        
        // Clear previous errors first
        this.minHeightError = null;
        this.maxHeightError = null;
        this.isValid = true; // Changed from isFormValid to isValid
        
        // Only validate if both values are present
        if (minHeight && maxHeight) {
          // Parse values to ensure proper numeric comparison
          const minValue = NumberFormatService.parseLocalizedNumber(minHeight);
          const maxValue = NumberFormatService.parseLocalizedNumber(maxHeight);
          
          console.log('Validating heights:', minValue, maxValue);
          
          // Explicit check to make sure comparison is correct
          if (minValue > maxValue) {
            this.isValid = false; // Changed from isFormValid to isValid
            this.minHeightError = this.$t('storage.rules.minShouldBeLessOrEqual');
            this.maxHeightError = this.$t('storage.rules.maxShouldBeGreaterOrEqual');
          }
          
          // Emit the validation state
          this.$emit('update:valid', this.isValid);
          
          // Force form validation refresh
          this.$nextTick(() => {
            this.$refs.form && this.$refs.form.validate();
          });
        }
      }
    },
    
    // Make sure validation runs after component is fully mounted
    mounted() {
      this.clearValidationErrors();
      this.$nextTick(() => {
        this.validateHeights();
      });
    }
  };
</script>

<style scoped>
  .display-field {
    padding: 8px;
    background-color: #f5f5f5;
    border-radius: .2rem;
    display: flex;
    align-items: center;
  }
</style>

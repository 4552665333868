export const formValidationMixin = {
  props: {
    // To allow parent components to force validation
    validateNow: {
      type: Boolean,
      default: false
    }
  },
  
  data() {
    return {
      isValid: true, // Change from isFormValid to isValid
      formTouched: false
    };
  },
  
  watch: {
    validateNow(val) {
      if (val) {
        this.validateForm();
      }
    }
  },
  
  methods: {
    validateForm() {
      // Check if form ref exists
      if (this.$refs.form) {
        this.isValid = this.$refs.form.validate(); // Update property name here
        this.$emit('update:valid', this.isValid);
        return this.isValid;
      }
      return true;
    },
    
    touchForm() {
      this.formTouched = true;
      this.validateForm();
    },
    
    resetValidation() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
        this.isValid = true; // Update property name here
        this.$emit('update:valid', true);
      }
    }
  },
  
  mounted() {
    // Initial validation
    this.$nextTick(() => {
      this.validateForm();
    });
  }
};
